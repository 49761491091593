import React, { useEffect, lazy, Suspense, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { message } from "antd";
import "./LoginStyles.css";
import LoadingComponent from "./components/Loading/LoadingComponent";
import Login from "./pages/login";
import { BrowserRouter, Routes, Route } from "react-router-dom";

const DataBioRouter = lazy(() => import("./routes"));

function LoginCheck() {
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false); // 新增状态

    useEffect(() => {
        const token = Cookies.get("token");
        const employee_id = Cookies.get("id");

        if (!token || !employee_id) {
            message.warning("Please login first");
            navigate("/login");
        } else {
            setIsLoggedIn(true);
        }
    }, [navigate]);

    if (!isLoggedIn) {
        return <Login />;
    }

    return (
        <Suspense fallback={<LoadingComponent />}>
            <DataBioRouter />
        </Suspense>
    );
}

export default LoginCheck;
