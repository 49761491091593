import {
    DATA_MANAGEMENT_STEPS,
    DATA_MANAGEMENT_OVERVIEW,
    DATA_MANAGEMENT_SELECTED_TEST_ID,
    DATA_MANAGEMENT_SEARCH_QUERY,
    DATA_MANAGEMENT_EDIT_DATA,
    DATA_MANAGEMENT_TYPE,
} from "@/store/actions/data_management/types";

const data_all = {
    steps: 0,
    overview: [],
    selected_test_id: "",
    search_query: {},
    edit_data: {},
    type: "",
};

const dataManagementDataSource = (state = data_all, action) => {
    switch (action.type) {
        case DATA_MANAGEMENT_STEPS:
            return {
                ...state,
                steps: action.payload,
            };
        case DATA_MANAGEMENT_OVERVIEW:
            return {
                ...state,
                overview: action.payload,
            };
        case DATA_MANAGEMENT_SELECTED_TEST_ID:
            return {
                ...state,
                selected_test_id: action.payload,
            };
        case DATA_MANAGEMENT_SEARCH_QUERY:
            return {
                ...state,
                search_query: action.payload,
            };
        case DATA_MANAGEMENT_EDIT_DATA:
            return {
                ...state,
                edit_data: action.payload,
            };

        case DATA_MANAGEMENT_TYPE:
            return {
                ...state,
                type: action.payload,
            };
        default:
            return state;
    }
};

export default dataManagementDataSource;
