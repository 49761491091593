import {
    CUSTOMER_ORDER_DATASOURCE,
    CUSTOMER_ORDER_EMPLOYEE_ID,
    CUSTOMER_ORDER_CUSTOMER_ID,
    CUSTOMER_ORDER_CUSTOMER_NAME,
    CUSTOMER_ORDER_CUSTOMER_GENDER,
    CUSTOMER_ORDER_CUSTOMER_PHONE,
    CUSTOMER_ORDER_STEPS,
    CUSTOMER_ORDER_CATEGORY,
    CUSTOMER_ORDER_CODE,
    CUSTOMER_ORDER_PAYMENT_INFO,
    CUSTOMER_ORDER_DRAFT_DATA,
} from "@/store/actions/cu_order/types";

const data_all = {
    dataSource: [],
    employeeId: undefined,
    customerId: undefined,
    customerName: undefined,
    customerGender: undefined,
    customerPhone: undefined,
    steps: 0,
    category: undefined,
    orderCode: undefined,
    orderPaymentInfo: {
        total: 0,
        debt: 0,
        paid: 0,
    },
    draftData: [],
};
const orderDataSource = (state = data_all, action) => {
    switch (action.type) {
        case CUSTOMER_ORDER_DATASOURCE:
            return {
                ...state,
                dataSource: action.payload,
            };
        case CUSTOMER_ORDER_EMPLOYEE_ID:
            return {
                ...state,
                employeeId: action.payload,
            };
        case CUSTOMER_ORDER_CUSTOMER_ID:
            return {
                ...state,
                customerId: action.payload,
            };
        case CUSTOMER_ORDER_CUSTOMER_NAME:
            return {
                ...state,
                customerName: action.payload,
            };
        case CUSTOMER_ORDER_CUSTOMER_GENDER:
            return {
                ...state,
                customerGender: action.payload,
            };
        case CUSTOMER_ORDER_CUSTOMER_PHONE:
            return {
                ...state,
                customerPhone: action.payload,
            };
        case CUSTOMER_ORDER_STEPS:
            return {
                ...state,
                steps: action.payload,
            };
        case CUSTOMER_ORDER_CATEGORY:
            return {
                ...state,
                category: action.payload,
            };
        case CUSTOMER_ORDER_CODE:
            return {
                ...state,
                orderCode: action.payload,
            };
        case CUSTOMER_ORDER_PAYMENT_INFO:
            return {
                ...state,
                orderPaymentInfo: action.payload,
            };
        case CUSTOMER_ORDER_DRAFT_DATA:
            return {
                ...state,
                draftData: action.payload,
            };

        default:
            return state;
    }
    // if (action.type === "customerInfoAppointment"){
    //     state = action.value,
    //     console.log('info state:', state, 'info action:', action)
    // }
};

export default orderDataSource;
