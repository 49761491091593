// 客户订单
export const CUSTOMER_ORDER_DATASOURCE = "CUSTOMER_ORDER_DATASOURCE";
export const CUSTOMER_ORDER_EMPLOYEE_ID = "CUSTOMER_ORDER_EMPLOYEE_ID";
export const CUSTOMER_ORDER_CUSTOMER_ID = "CUSTOMER_ORDER_CUSTOMER_ID";
export const CUSTOMER_ORDER_CUSTOMER_NAME = "CUSTOMER_ORDER_CUSTOMER_NAME";
export const CUSTOMER_ORDER_CUSTOMER_GENDER = "CUSTOMER_ORDER_CUSTOMER_GENDER";
export const CUSTOMER_ORDER_CUSTOMER_PHONE = "CUSTOMER_ORDER_CUSTOMER_PHONE";
export const CUSTOMER_ORDER_PAYMENT_INFO = "CUSTOMER_ORDER_PAYMENT_INFO";
export const CUSTOMER_ORDER_STEPS = "CUSTOMER_ORDER_STEPS";
export const CUSTOMER_ORDER_CATEGORY = "CUSTOMER_ORDER_CATEGORY";
export const CUSTOMER_ORDER_CODE = "CUSTOMER_ORDER_CODE";
export const CUSTOMER_ORDER_DRAFT_DATA = "CUSTOMER_ORDER_DRAFT_DATA";
