import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore } from "redux";
import reducer from "./store/reducers/index";
import LoginCheck from "./LoginCheck";
import Login from "./pages/login";
import { LoadingProvider } from "./routes/LoadingContext"; // Import LoadingProvider

const store = createStore(reducer);

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
            <LoadingProvider>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="*" element={<LoginCheck />} />
                </Routes>
            </LoadingProvider>
        </BrowserRouter>
    </Provider>,
    document.getElementById("root")
);
