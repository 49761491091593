import React from "react";
import "./LoadingStyles.css";

function LoadingComponent() {
    return (
        <div className="loading-container">
            <div class="spinner">
                <div class="rect1"></div>
                <div class="rect2"></div>
                <div class="rect3"></div>
                <div class="rect4"></div>
                <div class="rect5"></div>
            </div>
        </div>
    );
}

export default LoadingComponent;
