import axios from "axios";
import { message } from "antd";

import {
    URL_USER_LOGIN,
    URL_USER_CURRENT_USER,
    URL_USER_LOGOUT,
    URL_GET_USER_INFO,
    URL_UPDATE_USER_INFO,
    URL_CHANGE_PASSWORD,
} from "@/api/constants/users.js";

// error check
import { ApiErrorCheck } from "@/api/error_check";

// login api
const login = async (data) => {
    try {
        let response = await axios.post(URL_USER_LOGIN, data, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
        response = response.data;
        console.log("response", response);
        // change response into json dict

        return response;
    } catch (error) {
        ApiErrorCheck(error);
        console.log("error", error);
    }
};

// current user api
const getCurrentUser = async (data, token) => {
    try {
        const response = await axios.post(URL_USER_CURRENT_USER + "?token=" + token, data, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });

        const responseData = response.data;
        console.log("logout response", responseData);
        return responseData;
    } catch (error) {
        if (error.response.code === 401) {
            console.log("logout heere:" + error);
            message.error("Your session has expired. Please login again.");
        }
    }
};

// logout api
const logout = async (data, token) => {
    try {
        const response = await axios.post(URL_USER_LOGOUT + "?token=" + token, data, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });

        const responseData = response.data;
        console.log("logout response", responseData);
        return responseData;
    } catch (error) {
        ApiErrorCheck(error);
        console.log("error", error);
    }
};

// get user info api
const getUserInfo = async (data, token) => {
    try {
        const response = await axios.post(URL_GET_USER_INFO + "?token=" + token, data, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });

        const responseData = response.data;
        console.log("logout response", responseData);
        return responseData;
    } catch (error) {
        ApiErrorCheck(error);
        console.log("error", error);
    }
};

// update user info api
const updateUserInfo = async (data, token) => {
    try {
        const response = await axios.post(URL_UPDATE_USER_INFO + "?token=" + token, data, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });

        const responseData = response.data;
        console.log("logout response", responseData);
        return responseData;
    } catch (error) {
        ApiErrorCheck(error);
        console.log("error", error);
    }
};

// change password api
const changePassword = async (data, token) => {
    try {
        const response = await axios.post(URL_CHANGE_PASSWORD + "?token=" + token, data, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });

        const responseData = response.data;
        console.log("logout response", responseData);
        return responseData;
    } catch (error) {
        ApiErrorCheck(error);
        console.log("error", error);
    }
};

export { login, getCurrentUser, logout, getUserInfo, updateUserInfo, changePassword };
