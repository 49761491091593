// antd
import { message } from "antd";

// cookies
import Cookies from "js-cookie";

// const ApiErrorCheck = async (error) => {
//     if (error.response && error.response.status === 403) {
//         Cookies.remove("token");
//         Cookies.remove("id");
//         message.error("Token expired, please log in again");
//         return;
//     }
// };

const ApiErrorCheck = (error) => {
    message.error("Network error, please try again later");
    return;
};

export { ApiErrorCheck };
