// 客户服务
export const CUSTOMER_SERVICE_CUSTOMER_ID = "CUSTOMER_SERVICE_CUSTOMER_ID";
export const CUSTOMER_SERVICE_ORDER_CODE = "CUSTOMER_SERVICE_ORDER_CODE";
export const CUSTOMER_SERVICE_TRANS_CODE = "CUSTOMER_SERVICE_TRANS_CODE";
export const CUSTOMER_SERVICE_CUSTOMER_NAME = "CUSTOMER_SERVICE_CUSTOMER_NAME";
export const CUSTOMER_SERVICE_CUSTOMER_PHONE = "CUSTOMER_SERVICE_CUSTOMER_PHONE";
export const CUSTOMER_SERVICE_CUSTOMER_GENDER = "CUSTOMER_SERVICE_CUSTOMER_GENDER";
export const CUSTOMER_SERVICE_EMPLOYEE_ID = "CUSTOMER_SERVICE_EMPLOYEE_ID";
export const CUSTOMER_SERVICE_EMPLOYEE_NAME = "CUSTOMER_SERVICE_EMPLOYEE_NAME";
export const CUSTOMER_SERVICE_DEBT_AMOUNT = "CUSTOMER_SERVICE_DEBT_AMOUNT";
export const CUSTOMER_SERVICE_ORDER_TOTAL = "CUSTOMER_SERVICE_ORDER_TOTAL";
export const CUSTOMER_SERVICE_CUSTOMER_INFO = "CUSTOMER_SERVICE_CUSTOMER_INFO";
export const CUSTOMER_SERVICE_INFO = "CUSTOMER_SERVICE_INFO";
export const CUSTOMER_SERVICE_ORDER_DATASOURCE = "CUSTOMER_SERVICE_ORDER_DATASOURCE";
export const CUSTOMER_SERVICE_STEPS = "CUSTOMER_SERVICE_STEPS";
export const CUSTOMER_SERVICE_RECORD_ID_LIST = "CUSTOMER_SERVICE_RECORD_ID_LIST";
export const CUSTOMER_SERVICE_SELECTED_ROWS = "CUSTOMER_SERVICE_SELECTED_ROWS";
export const CUSTOMER_SERVICE_SELECTED_ROW_DATA = "CUSTOMER_SERVICE_SELECTED_ROW_DATA";
export const CUSTOMER_SERVICE_DETAILS = "CUSTOMER_SERVICE_DETAILS";
export const CUSTOMER_SERVICE_CODE = "CUSTOMER_SERVICE_CODE";
export const CUSTOMER_SERVICE_CART = "CUSTOMER_SERVICE_CART";
export const CUSTOMER_SERVICE_CART_TEMP = "CUSTOMER_SERVICE_CART_TEMP";
