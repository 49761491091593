import {
    // 客户服务
    CUSTOMER_SERVICE_CUSTOMER_ID,
    CUSTOMER_SERVICE_ORDER_CODE,
    CUSTOMER_SERVICE_TRANS_CODE,
    CUSTOMER_SERVICE_CUSTOMER_NAME,
    CUSTOMER_SERVICE_CUSTOMER_PHONE,
    CUSTOMER_SERVICE_CUSTOMER_GENDER,
    CUSTOMER_SERVICE_EMPLOYEE_ID,
    CUSTOMER_SERVICE_EMPLOYEE_NAME,
    CUSTOMER_SERVICE_DEBT_AMOUNT,
    CUSTOMER_SERVICE_ORDER_TOTAL,
    CUSTOMER_SERVICE_CUSTOMER_INFO,
    CUSTOMER_SERVICE_INFO,
    CUSTOMER_SERVICE_ORDER_DATASOURCE,
    CUSTOMER_SERVICE_STEPS,
    CUSTOMER_SERVICE_RECORD_ID_LIST, // 新替换，用于记录选中的id
    CUSTOMER_SERVICE_SELECTED_ROWS,
    CUSTOMER_SERVICE_SELECTED_ROW_DATA,
    CUSTOMER_SERVICE_DETAILS,
    CUSTOMER_SERVICE_CODE,
    CUSTOMER_SERVICE_CART,
    CUSTOMER_SERVICE_CART_TEMP, // 临时数据
} from "@/store/actions/cu_service/types";

const data_all = {
    customerId: undefined,
    orderCode: undefined,
    transCode: undefined,
    customerName: undefined,
    customerPhone: undefined,
    customerGender: undefined,
    employeeId: undefined,
    employeeName: undefined,
    debtAmount: undefined,
    orderTotal: undefined,
    // 给Step4用
    // customerInfo: {
    //     customer_id: "3",
    //     name: "祖戈宁",
    //     gender: "女",
    //     phone1: "13892329883",
    //     age: "",
    //     tag: ["会员"],
    //     avatar: "https://bk.lan.jlwspa.com/media/avatar/female_avatar.png",
    // },
    // serviceInfo: {
    //     overview: {
    //         service_code: "SV20230707679237",
    //         shop: "金兰湾白鹭洲店",
    //         room: "茉莉房 (B2)",
    //         service_date: "2023-07-06",
    //         service_time_start: "10:25",
    //         service_time_finish: "12:00",
    //         duration: "1 小时 35 分钟",
    //         beautician: ["user1", "user2", "user3"],
    //         create_person: "user2",
    //         create_time: "2023-07-07 09:56:06",
    //         notes: "ddd",
    //     },
    //     details: [
    //         {
    //             key: 117,
    //             service_name: "oxyjet-低压喷氧",
    //             service_name_en: "oxyjet-Low Pressure Oxygen Spray",
    //             category: ["服务"],
    //             total: 1,
    //             consume: "1",
    //             rest: 0,
    //             service_real_price: "980.00",
    //             beautician: ["user1", "user2", "user3"],
    //         },
    //         {
    //             key: 116,
    //             service_name: "水研醒肤焕颜精华护理",
    //             service_name_en: "Hyalogy Skin Revitalizing Radiance Essence Care",
    //             category: ["疗程"],
    //             total: 3,
    //             consume: "1",
    //             rest: 1,
    //             service_real_price: "980.00",
    //             beautician: ["user1", "user3"],
    //         },
    //         {
    //             key: 115,
    //             service_name: "美舒黛透明质酸水润提升护理",
    //             service_name_en: "Massada Hyaluronic Acid Moisture Boost Care",
    //             category: ["疗程"],
    //             total: 3,
    //             consume: "1",
    //             rest: 2,
    //             service_real_price: "980.00",
    //             beautician: ["user2", "user3"],
    //         },
    //         {
    //             key: 114,
    //             service_name: "美舒黛珍珠透润抗皱亮肌护理",
    //             service_name_en: "Massada Pearl Hydration Anti-Wrinkle Brightening Care",
    //             category: ["服务"],
    //             total: 1,
    //             consume: "1",
    //             rest: 0,
    //             service_real_price: "980.00",
    //             beautician: ["user1", "user3"],
    //         },
    //         {
    //             key: 112,
    //             service_name: "美舒黛珍珠透润抗皱亮肌护理",
    //             service_name_en: "Massada Pearl Hydration Anti-Wrinkle Brightening Care",
    //             category: ["疗程"],
    //             total: 5,
    //             consume: "1",
    //             rest: 2,
    //             service_real_price: "670.00",
    //             beautician: ["user3"],
    //         },
    //     ],
    //     balance: [
    //         {
    //             name: "oxyjet-低压喷氧",
    //             service_times: 138,
    //             rest: 138,
    //             details: [
    //                 {
    //                     key: 2,
    //                     category: ["疗程"],
    //                     service_id: "18",
    //                     service_name: "oxyjet-低压喷氧",
    //                     order_code: "/",
    //                     create_time: "/",
    //                     stored_time: "2023-06-01 21:28:37",
    //                     valid_term: "短期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: 0,
    //                     service_total_price: 0,
    //                     service_single_price: 0,
    //                     order_create_person: "/",
    //                     order_create_shop: "/",
    //                     stored_create_person: "user2",
    //                     notes: "/",
    //                 },
    //                 {
    //                     key: 4,
    //                     category: ["疗程"],
    //                     service_id: "18",
    //                     service_name: "oxyjet-低压喷氧",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-01 21:29:37",
    //                     stored_time: "2023-06-01 21:29:37",
    //                     valid_term: "短期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "533.00",
    //                     service_single_price: "741.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 6,
    //                     category: ["疗程"],
    //                     service_id: "18",
    //                     service_name: "oxyjet-低压喷氧",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-01 21:30:26",
    //                     stored_time: "2023-06-01 21:30:26",
    //                     valid_term: "短期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "533.00",
    //                     service_single_price: "741.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 8,
    //                     category: ["疗程"],
    //                     service_id: "18",
    //                     service_name: "oxyjet-低压喷氧",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-01 21:31:50",
    //                     stored_time: "2023-06-01 21:31:49",
    //                     valid_term: "短期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "533.00",
    //                     service_single_price: "741.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 10,
    //                     category: ["疗程"],
    //                     service_id: "18",
    //                     service_name: "oxyjet-低压喷氧",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-01 21:32:24",
    //                     stored_time: "2023-06-01 21:32:24",
    //                     valid_term: "短期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "533.00",
    //                     service_single_price: "741.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 12,
    //                     category: ["疗程"],
    //                     service_id: "18",
    //                     service_name: "oxyjet-低压喷氧",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-01 21:33:25",
    //                     stored_time: "2023-06-01 21:33:25",
    //                     valid_term: "短期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "533.00",
    //                     service_single_price: "741.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 14,
    //                     category: ["疗程"],
    //                     service_id: "18",
    //                     service_name: "oxyjet-低压喷氧",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-01 21:36:16",
    //                     stored_time: "2023-06-01 21:36:15",
    //                     valid_term: "短期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "533.00",
    //                     service_single_price: "741.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 16,
    //                     category: ["疗程"],
    //                     service_id: "18",
    //                     service_name: "oxyjet-低压喷氧",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-01 21:42:45",
    //                     stored_time: "2023-06-01 21:42:45",
    //                     valid_term: "短期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "533.00",
    //                     service_single_price: "741.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 18,
    //                     category: ["疗程"],
    //                     service_id: "18",
    //                     service_name: "oxyjet-低压喷氧",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-02 07:35:56",
    //                     stored_time: "2023-06-02 07:35:56",
    //                     valid_term: "短期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "533.00",
    //                     service_single_price: "741.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 20,
    //                     category: ["疗程"],
    //                     service_id: "18",
    //                     service_name: "oxyjet-低压喷氧",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-03 10:59:05",
    //                     stored_time: "2023-06-03 10:59:04",
    //                     valid_term: "短期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "533.00",
    //                     service_single_price: "741.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 22,
    //                     category: ["疗程"],
    //                     service_id: "18",
    //                     service_name: "oxyjet-低压喷氧",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-03 11:13:22",
    //                     stored_time: "2023-06-03 11:13:22",
    //                     valid_term: "短期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "533.00",
    //                     service_single_price: "741.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 24,
    //                     category: ["疗程"],
    //                     service_id: "18",
    //                     service_name: "oxyjet-低压喷氧",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-03 11:14:08",
    //                     stored_time: "2023-06-03 11:14:08",
    //                     valid_term: "短期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "533.00",
    //                     service_single_price: "741.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 26,
    //                     category: ["疗程"],
    //                     service_id: "18",
    //                     service_name: "oxyjet-低压喷氧",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-03 11:14:30",
    //                     stored_time: "2023-06-03 11:14:30",
    //                     valid_term: "短期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "533.00",
    //                     service_single_price: "741.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 28,
    //                     category: ["疗程"],
    //                     service_id: "18",
    //                     service_name: "oxyjet-低压喷氧",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-04 17:50:15",
    //                     stored_time: "2023-06-04 17:50:14",
    //                     valid_term: "短期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "533.00",
    //                     service_single_price: "741.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 30,
    //                     category: ["疗程"],
    //                     service_id: "18",
    //                     service_name: "oxyjet-低压喷氧",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-04 17:55:51",
    //                     stored_time: "2023-06-04 17:55:50",
    //                     valid_term: "短期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "533.00",
    //                     service_single_price: "741.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 32,
    //                     category: ["疗程"],
    //                     service_id: "18",
    //                     service_name: "oxyjet-低压喷氧",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-04 17:56:03",
    //                     stored_time: "2023-06-04 17:56:03",
    //                     valid_term: "短期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "533.00",
    //                     service_single_price: "741.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 34,
    //                     category: ["疗程"],
    //                     service_id: "18",
    //                     service_name: "oxyjet-低压喷氧",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-04 17:57:59",
    //                     stored_time: "2023-06-04 17:57:59",
    //                     valid_term: "短期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "533.00",
    //                     service_single_price: "741.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 36,
    //                     category: ["疗程"],
    //                     service_id: "18",
    //                     service_name: "oxyjet-低压喷氧",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-04 17:58:50",
    //                     stored_time: "2023-06-04 17:58:50",
    //                     valid_term: "短期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "533.00",
    //                     service_single_price: "741.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 38,
    //                     category: ["疗程"],
    //                     service_id: "18",
    //                     service_name: "oxyjet-低压喷氧",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-04 18:02:06",
    //                     stored_time: "2023-06-04 18:02:06",
    //                     valid_term: "短期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "533.00",
    //                     service_single_price: "741.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 40,
    //                     category: ["疗程"],
    //                     service_id: "18",
    //                     service_name: "oxyjet-低压喷氧",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-04 18:03:00",
    //                     stored_time: "2023-06-04 18:03:00",
    //                     valid_term: "短期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "533.00",
    //                     service_single_price: "741.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 42,
    //                     category: ["疗程"],
    //                     service_id: "18",
    //                     service_name: "oxyjet-低压喷氧",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-04 18:03:42",
    //                     stored_time: "2023-06-04 18:03:41",
    //                     valid_term: "短期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "533.00",
    //                     service_single_price: "741.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 44,
    //                     category: ["疗程"],
    //                     service_id: "18",
    //                     service_name: "oxyjet-低压喷氧",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-04 18:03:59",
    //                     stored_time: "2023-06-04 18:03:59",
    //                     valid_term: "短期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "533.00",
    //                     service_single_price: "741.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 46,
    //                     category: ["疗程"],
    //                     service_id: "18",
    //                     service_name: "oxyjet-低压喷氧",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-04 18:04:34",
    //                     stored_time: "2023-06-04 18:04:34",
    //                     valid_term: "短期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "533.00",
    //                     service_single_price: "741.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 48,
    //                     category: ["疗程"],
    //                     service_id: "18",
    //                     service_name: "oxyjet-低压喷氧",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-04 18:04:56",
    //                     stored_time: "2023-06-04 18:04:56",
    //                     valid_term: "短期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "533.00",
    //                     service_single_price: "741.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 50,
    //                     category: ["疗程"],
    //                     service_id: "18",
    //                     service_name: "oxyjet-低压喷氧",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-04 18:06:35",
    //                     stored_time: "2023-06-04 18:06:34",
    //                     valid_term: "短期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "533.00",
    //                     service_single_price: "741.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 52,
    //                     category: ["疗程"],
    //                     service_id: "18",
    //                     service_name: "oxyjet-低压喷氧",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-04 19:40:29",
    //                     stored_time: "2023-06-04 19:40:29",
    //                     valid_term: "短期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "533.00",
    //                     service_single_price: "741.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 54,
    //                     category: ["疗程"],
    //                     service_id: "18",
    //                     service_name: "oxyjet-低压喷氧",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-04 20:28:29",
    //                     stored_time: "2023-06-04 20:28:29",
    //                     valid_term: "短期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "533.00",
    //                     service_single_price: "741.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 56,
    //                     category: ["疗程"],
    //                     service_id: "18",
    //                     service_name: "oxyjet-低压喷氧",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-04 20:30:47",
    //                     stored_time: "2023-06-04 20:30:47",
    //                     valid_term: "短期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "533.00",
    //                     service_single_price: "741.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 58,
    //                     category: ["疗程"],
    //                     service_id: "18",
    //                     service_name: "oxyjet-低压喷氧",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-04 21:07:13",
    //                     stored_time: "2023-06-04 21:07:13",
    //                     valid_term: "短期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "533.00",
    //                     service_single_price: "741.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 60,
    //                     category: ["疗程"],
    //                     service_id: "18",
    //                     service_name: "oxyjet-低压喷氧",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-04 21:07:43",
    //                     stored_time: "2023-06-04 21:07:43",
    //                     valid_term: "短期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "533.00",
    //                     service_single_price: "741.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 62,
    //                     category: ["疗程"],
    //                     service_id: "18",
    //                     service_name: "oxyjet-低压喷氧",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-04 21:20:21",
    //                     stored_time: "2023-06-04 21:20:21",
    //                     valid_term: "短期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "533.00",
    //                     service_single_price: "741.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 64,
    //                     category: ["疗程"],
    //                     service_id: "18",
    //                     service_name: "oxyjet-低压喷氧",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-04 21:23:26",
    //                     stored_time: "2023-06-04 21:23:26",
    //                     valid_term: "短期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "533.00",
    //                     service_single_price: "741.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 66,
    //                     category: ["疗程"],
    //                     service_id: "18",
    //                     service_name: "oxyjet-低压喷氧",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-04 21:29:54",
    //                     stored_time: "2023-06-04 21:29:54",
    //                     valid_term: "短期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "533.00",
    //                     service_single_price: "741.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 68,
    //                     category: ["疗程"],
    //                     service_id: "18",
    //                     service_name: "oxyjet-低压喷氧",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-04 21:31:41",
    //                     stored_time: "2023-06-04 21:31:41",
    //                     valid_term: "短期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "533.00",
    //                     service_single_price: "741.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 70,
    //                     category: ["疗程"],
    //                     service_id: "18",
    //                     service_name: "oxyjet-低压喷氧",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-04 21:32:06",
    //                     stored_time: "2023-06-04 21:32:05",
    //                     valid_term: "短期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "533.00",
    //                     service_single_price: "741.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 72,
    //                     category: ["疗程"],
    //                     service_id: "18",
    //                     service_name: "oxyjet-低压喷氧",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-04 21:32:58",
    //                     stored_time: "2023-06-04 21:32:57",
    //                     valid_term: "短期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "533.00",
    //                     service_single_price: "741.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 74,
    //                     category: ["疗程"],
    //                     service_id: "18",
    //                     service_name: "oxyjet-低压喷氧",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-04 21:33:39",
    //                     stored_time: "2023-06-04 21:33:39",
    //                     valid_term: "短期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "533.00",
    //                     service_single_price: "741.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 76,
    //                     category: ["疗程"],
    //                     service_id: "18",
    //                     service_name: "oxyjet-低压喷氧",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-04 21:34:11",
    //                     stored_time: "2023-06-04 21:34:11",
    //                     valid_term: "短期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "533.00",
    //                     service_single_price: "741.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 78,
    //                     category: ["疗程"],
    //                     service_id: "18",
    //                     service_name: "oxyjet-低压喷氧",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-04 21:38:26",
    //                     stored_time: "2023-06-04 21:38:26",
    //                     valid_term: "短期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "533.00",
    //                     service_single_price: "741.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 80,
    //                     category: ["疗程"],
    //                     service_id: "18",
    //                     service_name: "oxyjet-低压喷氧",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-04 22:13:48",
    //                     stored_time: "2023-06-04 22:13:48",
    //                     valid_term: "短期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "533.00",
    //                     service_single_price: "741.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 82,
    //                     category: ["疗程"],
    //                     service_id: "18",
    //                     service_name: "oxyjet-低压喷氧",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-05 16:27:57",
    //                     stored_time: "2023-06-05 16:27:57",
    //                     valid_term: "短期有效",
    //                     valid_time: "/",
    //                     total: 8,
    //                     rest: 8,
    //                     order_total: "2636.00",
    //                     service_total_price: "533.00",
    //                     service_single_price: "741.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 84,
    //                     category: ["疗程"],
    //                     service_id: "18",
    //                     service_name: "oxyjet-低压喷氧",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-07 10:13:44",
    //                     stored_time: "2023-06-07 10:13:44",
    //                     valid_term: "短期有效",
    //                     valid_time: "/",
    //                     total: 8,
    //                     rest: 8,
    //                     order_total: "2636.00",
    //                     service_total_price: "533.00",
    //                     service_single_price: "741.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 86,
    //                     category: ["疗程"],
    //                     service_id: "18",
    //                     service_name: "oxyjet-低压喷氧",
    //                     order_code: "OD20230611289017",
    //                     create_time: "2023-06-10 18:09:35",
    //                     stored_time: "2023-06-10 18:09:35",
    //                     valid_term: "短期有效",
    //                     valid_time: "/",
    //                     total: 2,
    //                     rest: 2,
    //                     order_total: "10200.00",
    //                     service_total_price: "1200.00",
    //                     service_single_price: "600.00",
    //                     order_create_person: "user2",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //             ],
    //             key: 1,
    //         },
    //         {
    //             name: "泰式Double香薰蜜蜡热油SPA",
    //             service_times: 114,
    //             rest: 114,
    //             details: [
    //                 {
    //                     key: 1,
    //                     category: ["疗程"],
    //                     service_id: "29",
    //                     service_name: "泰式Double香薰蜜蜡热油SPA",
    //                     order_code: "/",
    //                     create_time: "/",
    //                     stored_time: "2023-06-01 21:28:37",
    //                     valid_term: "长期有效",
    //                     valid_time: "/",
    //                     total: 2,
    //                     rest: 2,
    //                     order_total: 0,
    //                     service_total_price: 0,
    //                     service_single_price: 0,
    //                     order_create_person: "/",
    //                     order_create_shop: "/",
    //                     stored_create_person: "user2",
    //                     notes: "/",
    //                 },
    //                 {
    //                     key: 3,
    //                     category: ["疗程"],
    //                     service_id: "29",
    //                     service_name: "泰式Double香薰蜜蜡热油SPA",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-01 21:29:37",
    //                     stored_time: "2023-06-01 21:29:37",
    //                     valid_term: "长期有效",
    //                     valid_time: "/",
    //                     total: 2,
    //                     rest: 2,
    //                     order_total: "2636.00",
    //                     service_total_price: "2233.00",
    //                     service_single_price: "111.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 5,
    //                     category: ["疗程"],
    //                     service_id: "29",
    //                     service_name: "泰式Double香薰蜜蜡热油SPA",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-01 21:30:26",
    //                     stored_time: "2023-06-01 21:30:26",
    //                     valid_term: "长期有效",
    //                     valid_time: "/",
    //                     total: 2,
    //                     rest: 2,
    //                     order_total: "2636.00",
    //                     service_total_price: "2233.00",
    //                     service_single_price: "111.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 7,
    //                     category: ["疗程"],
    //                     service_id: "29",
    //                     service_name: "泰式Double香薰蜜蜡热油SPA",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-01 21:31:50",
    //                     stored_time: "2023-06-01 21:31:49",
    //                     valid_term: "长期有效",
    //                     valid_time: "/",
    //                     total: 2,
    //                     rest: 2,
    //                     order_total: "2636.00",
    //                     service_total_price: "2233.00",
    //                     service_single_price: "111.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 9,
    //                     category: ["疗程"],
    //                     service_id: "29",
    //                     service_name: "泰式Double香薰蜜蜡热油SPA",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-01 21:32:24",
    //                     stored_time: "2023-06-01 21:32:24",
    //                     valid_term: "长期有效",
    //                     valid_time: "/",
    //                     total: 2,
    //                     rest: 2,
    //                     order_total: "2636.00",
    //                     service_total_price: "2233.00",
    //                     service_single_price: "111.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 11,
    //                     category: ["疗程"],
    //                     service_id: "29",
    //                     service_name: "泰式Double香薰蜜蜡热油SPA",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-01 21:33:25",
    //                     stored_time: "2023-06-01 21:33:25",
    //                     valid_term: "长期有效",
    //                     valid_time: "/",
    //                     total: 2,
    //                     rest: 2,
    //                     order_total: "2636.00",
    //                     service_total_price: "2233.00",
    //                     service_single_price: "111.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 13,
    //                     category: ["疗程"],
    //                     service_id: "29",
    //                     service_name: "泰式Double香薰蜜蜡热油SPA",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-01 21:36:16",
    //                     stored_time: "2023-06-01 21:36:15",
    //                     valid_term: "长期有效",
    //                     valid_time: "/",
    //                     total: 2,
    //                     rest: 2,
    //                     order_total: "2636.00",
    //                     service_total_price: "2233.00",
    //                     service_single_price: "111.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 15,
    //                     category: ["疗程"],
    //                     service_id: "29",
    //                     service_name: "泰式Double香薰蜜蜡热油SPA",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-01 21:42:45",
    //                     stored_time: "2023-06-01 21:42:45",
    //                     valid_term: "长期有效",
    //                     valid_time: "/",
    //                     total: 2,
    //                     rest: 2,
    //                     order_total: "2636.00",
    //                     service_total_price: "2233.00",
    //                     service_single_price: "111.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 17,
    //                     category: ["疗程"],
    //                     service_id: "29",
    //                     service_name: "泰式Double香薰蜜蜡热油SPA",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-02 07:35:56",
    //                     stored_time: "2023-06-02 07:35:56",
    //                     valid_term: "长期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "2233.00",
    //                     service_single_price: "111.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 19,
    //                     category: ["疗程"],
    //                     service_id: "29",
    //                     service_name: "泰式Double香薰蜜蜡热油SPA",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-03 10:59:05",
    //                     stored_time: "2023-06-03 10:59:04",
    //                     valid_term: "长期有效",
    //                     valid_time: "/",
    //                     total: 2,
    //                     rest: 2,
    //                     order_total: "2636.00",
    //                     service_total_price: "2233.00",
    //                     service_single_price: "111.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 21,
    //                     category: ["疗程"],
    //                     service_id: "29",
    //                     service_name: "泰式Double香薰蜜蜡热油SPA",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-03 11:13:22",
    //                     stored_time: "2023-06-03 11:13:22",
    //                     valid_term: "长期有效",
    //                     valid_time: "/",
    //                     total: 2,
    //                     rest: 2,
    //                     order_total: "2636.00",
    //                     service_total_price: "2233.00",
    //                     service_single_price: "111.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 23,
    //                     category: ["疗程"],
    //                     service_id: "29",
    //                     service_name: "泰式Double香薰蜜蜡热油SPA",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-03 11:14:08",
    //                     stored_time: "2023-06-03 11:14:07",
    //                     valid_term: "长期有效",
    //                     valid_time: "/",
    //                     total: 2,
    //                     rest: 2,
    //                     order_total: "2636.00",
    //                     service_total_price: "2233.00",
    //                     service_single_price: "111.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 25,
    //                     category: ["疗程"],
    //                     service_id: "29",
    //                     service_name: "泰式Double香薰蜜蜡热油SPA",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-03 11:14:30",
    //                     stored_time: "2023-06-03 11:14:30",
    //                     valid_term: "长期有效",
    //                     valid_time: "/",
    //                     total: 2,
    //                     rest: 2,
    //                     order_total: "2636.00",
    //                     service_total_price: "2233.00",
    //                     service_single_price: "111.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 27,
    //                     category: ["疗程"],
    //                     service_id: "29",
    //                     service_name: "泰式Double香薰蜜蜡热油SPA",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-04 17:50:15",
    //                     stored_time: "2023-06-04 17:50:14",
    //                     valid_term: "长期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "2233.00",
    //                     service_single_price: "111.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 29,
    //                     category: ["疗程"],
    //                     service_id: "29",
    //                     service_name: "泰式Double香薰蜜蜡热油SPA",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-04 17:55:51",
    //                     stored_time: "2023-06-04 17:55:50",
    //                     valid_term: "长期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "2233.00",
    //                     service_single_price: "111.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 31,
    //                     category: ["疗程"],
    //                     service_id: "29",
    //                     service_name: "泰式Double香薰蜜蜡热油SPA",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-04 17:56:03",
    //                     stored_time: "2023-06-04 17:56:03",
    //                     valid_term: "长期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "2233.00",
    //                     service_single_price: "111.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 33,
    //                     category: ["疗程"],
    //                     service_id: "29",
    //                     service_name: "泰式Double香薰蜜蜡热油SPA",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-04 17:57:59",
    //                     stored_time: "2023-06-04 17:57:59",
    //                     valid_term: "长期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "2233.00",
    //                     service_single_price: "111.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 35,
    //                     category: ["疗程"],
    //                     service_id: "29",
    //                     service_name: "泰式Double香薰蜜蜡热油SPA",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-04 17:58:50",
    //                     stored_time: "2023-06-04 17:58:50",
    //                     valid_term: "长期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "2233.00",
    //                     service_single_price: "111.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 37,
    //                     category: ["疗程"],
    //                     service_id: "29",
    //                     service_name: "泰式Double香薰蜜蜡热油SPA",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-04 18:02:06",
    //                     stored_time: "2023-06-04 18:02:05",
    //                     valid_term: "长期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "2233.00",
    //                     service_single_price: "111.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 39,
    //                     category: ["疗程"],
    //                     service_id: "29",
    //                     service_name: "泰式Double香薰蜜蜡热油SPA",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-04 18:03:00",
    //                     stored_time: "2023-06-04 18:03:00",
    //                     valid_term: "长期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "2233.00",
    //                     service_single_price: "111.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 41,
    //                     category: ["疗程"],
    //                     service_id: "29",
    //                     service_name: "泰式Double香薰蜜蜡热油SPA",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-04 18:03:42",
    //                     stored_time: "2023-06-04 18:03:41",
    //                     valid_term: "长期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "2233.00",
    //                     service_single_price: "111.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 43,
    //                     category: ["疗程"],
    //                     service_id: "29",
    //                     service_name: "泰式Double香薰蜜蜡热油SPA",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-04 18:03:59",
    //                     stored_time: "2023-06-04 18:03:59",
    //                     valid_term: "长期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "2233.00",
    //                     service_single_price: "111.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 45,
    //                     category: ["疗程"],
    //                     service_id: "29",
    //                     service_name: "泰式Double香薰蜜蜡热油SPA",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-04 18:04:34",
    //                     stored_time: "2023-06-04 18:04:34",
    //                     valid_term: "长期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "2233.00",
    //                     service_single_price: "111.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 47,
    //                     category: ["疗程"],
    //                     service_id: "29",
    //                     service_name: "泰式Double香薰蜜蜡热油SPA",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-04 18:04:56",
    //                     stored_time: "2023-06-04 18:04:56",
    //                     valid_term: "长期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "2233.00",
    //                     service_single_price: "111.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 49,
    //                     category: ["疗程"],
    //                     service_id: "29",
    //                     service_name: "泰式Double香薰蜜蜡热油SPA",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-04 18:06:35",
    //                     stored_time: "2023-06-04 18:06:34",
    //                     valid_term: "长期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "2233.00",
    //                     service_single_price: "111.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 51,
    //                     category: ["疗程"],
    //                     service_id: "29",
    //                     service_name: "泰式Double香薰蜜蜡热油SPA",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-04 19:40:29",
    //                     stored_time: "2023-06-04 19:40:29",
    //                     valid_term: "长期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "2233.00",
    //                     service_single_price: "111.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 53,
    //                     category: ["疗程"],
    //                     service_id: "29",
    //                     service_name: "泰式Double香薰蜜蜡热油SPA",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-04 20:28:29",
    //                     stored_time: "2023-06-04 20:28:28",
    //                     valid_term: "长期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "2233.00",
    //                     service_single_price: "111.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 55,
    //                     category: ["疗程"],
    //                     service_id: "29",
    //                     service_name: "泰式Double香薰蜜蜡热油SPA",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-04 20:30:47",
    //                     stored_time: "2023-06-04 20:30:47",
    //                     valid_term: "长期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "2233.00",
    //                     service_single_price: "111.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 57,
    //                     category: ["疗程"],
    //                     service_id: "29",
    //                     service_name: "泰式Double香薰蜜蜡热油SPA",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-04 21:07:13",
    //                     stored_time: "2023-06-04 21:07:13",
    //                     valid_term: "长期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "2233.00",
    //                     service_single_price: "111.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 59,
    //                     category: ["疗程"],
    //                     service_id: "29",
    //                     service_name: "泰式Double香薰蜜蜡热油SPA",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-04 21:07:43",
    //                     stored_time: "2023-06-04 21:07:43",
    //                     valid_term: "长期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "2233.00",
    //                     service_single_price: "111.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 61,
    //                     category: ["疗程"],
    //                     service_id: "29",
    //                     service_name: "泰式Double香薰蜜蜡热油SPA",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-04 21:20:21",
    //                     stored_time: "2023-06-04 21:20:21",
    //                     valid_term: "长期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "2233.00",
    //                     service_single_price: "111.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 63,
    //                     category: ["疗程"],
    //                     service_id: "29",
    //                     service_name: "泰式Double香薰蜜蜡热油SPA",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-04 21:23:26",
    //                     stored_time: "2023-06-04 21:23:26",
    //                     valid_term: "长期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "2233.00",
    //                     service_single_price: "111.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 65,
    //                     category: ["疗程"],
    //                     service_id: "29",
    //                     service_name: "泰式Double香薰蜜蜡热油SPA",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-04 21:29:54",
    //                     stored_time: "2023-06-04 21:29:54",
    //                     valid_term: "长期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "2233.00",
    //                     service_single_price: "111.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 67,
    //                     category: ["疗程"],
    //                     service_id: "29",
    //                     service_name: "泰式Double香薰蜜蜡热油SPA",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-04 21:31:41",
    //                     stored_time: "2023-06-04 21:31:41",
    //                     valid_term: "长期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "2233.00",
    //                     service_single_price: "111.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 69,
    //                     category: ["疗程"],
    //                     service_id: "29",
    //                     service_name: "泰式Double香薰蜜蜡热油SPA",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-04 21:32:06",
    //                     stored_time: "2023-06-04 21:32:05",
    //                     valid_term: "长期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "2233.00",
    //                     service_single_price: "111.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 71,
    //                     category: ["疗程"],
    //                     service_id: "29",
    //                     service_name: "泰式Double香薰蜜蜡热油SPA",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-04 21:32:58",
    //                     stored_time: "2023-06-04 21:32:57",
    //                     valid_term: "长期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "2233.00",
    //                     service_single_price: "111.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 73,
    //                     category: ["疗程"],
    //                     service_id: "29",
    //                     service_name: "泰式Double香薰蜜蜡热油SPA",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-04 21:33:39",
    //                     stored_time: "2023-06-04 21:33:39",
    //                     valid_term: "长期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "2233.00",
    //                     service_single_price: "111.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 75,
    //                     category: ["疗程"],
    //                     service_id: "29",
    //                     service_name: "泰式Double香薰蜜蜡热油SPA",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-04 21:34:11",
    //                     stored_time: "2023-06-04 21:34:11",
    //                     valid_term: "长期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "2233.00",
    //                     service_single_price: "111.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 77,
    //                     category: ["疗程"],
    //                     service_id: "29",
    //                     service_name: "泰式Double香薰蜜蜡热油SPA",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-04 21:38:26",
    //                     stored_time: "2023-06-04 21:38:26",
    //                     valid_term: "长期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "2233.00",
    //                     service_single_price: "111.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 79,
    //                     category: ["疗程"],
    //                     service_id: "29",
    //                     service_name: "泰式Double香薰蜜蜡热油SPA",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-04 22:13:48",
    //                     stored_time: "2023-06-04 22:13:48",
    //                     valid_term: "长期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "2233.00",
    //                     service_single_price: "111.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 81,
    //                     category: ["疗程"],
    //                     service_id: "29",
    //                     service_name: "泰式Double香薰蜜蜡热油SPA",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-05 16:27:57",
    //                     stored_time: "2023-06-05 16:27:57",
    //                     valid_term: "短期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "2233.00",
    //                     service_single_price: "111.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 83,
    //                     category: ["疗程"],
    //                     service_id: "29",
    //                     service_name: "泰式Double香薰蜜蜡热油SPA",
    //                     order_code: "OD20230506130689",
    //                     create_time: "2023-06-07 10:13:44",
    //                     stored_time: "2023-06-07 10:13:44",
    //                     valid_term: "短期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 3,
    //                     order_total: "2636.00",
    //                     service_total_price: "2233.00",
    //                     service_single_price: "111.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //             ],
    //             key: 2,
    //         },
    //         {
    //             name: "水研美颜细润精华护理",
    //             service_times: 12,
    //             rest: 10,
    //             details: [
    //                 {
    //                     key: 90,
    //                     category: ["疗程"],
    //                     service_id: "2",
    //                     service_name: "水研美颜细润精华护理",
    //                     order_code: "OD20230619351807",
    //                     create_time: "2023-06-19 09:17:56",
    //                     stored_time: "2023-06-19 09:17:55",
    //                     valid_term: "长期有效",
    //                     valid_time: "/",
    //                     total: 6,
    //                     rest: 6,
    //                     order_total: "2380.00",
    //                     service_total_price: "2380.00",
    //                     service_single_price: "396.67",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user1",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 91,
    //                     category: ["疗程"],
    //                     service_id: "2",
    //                     service_name: "水研美颜细润精华护理",
    //                     order_code: "OD20230619881345",
    //                     create_time: "2023-06-19 09:19:49",
    //                     stored_time: "2023-06-19 09:19:49",
    //                     valid_term: "长期有效",
    //                     valid_time: "/",
    //                     total: 6,
    //                     rest: 4,
    //                     order_total: "2380.00",
    //                     service_total_price: "2380.00",
    //                     service_single_price: "396.67",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user1",
    //                     notes: "",
    //                 },
    //             ],
    //             key: 3,
    //         },
    //         {
    //             name: "水研水营活化护理",
    //             service_times: 8,
    //             rest: 6,
    //             details: [
    //                 {
    //                     key: 89,
    //                     category: ["疗程"],
    //                     service_id: "1",
    //                     service_name: "水研水营活化护理",
    //                     order_code: "OD20230619446983",
    //                     create_time: "2023-06-19 08:22:46",
    //                     stored_time: "2023-06-19 08:22:46",
    //                     valid_term: "长期有效",
    //                     valid_time: "/",
    //                     total: 6,
    //                     rest: 5,
    //                     order_total: "2280.00",
    //                     service_total_price: "2280.00",
    //                     service_single_price: "380.00",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user1",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 93,
    //                     category: ["疗程"],
    //                     service_id: "1",
    //                     service_name: "水研水营活化护理",
    //                     order_code: "OD20230629282960",
    //                     create_time: "2023-06-29 14:24:44",
    //                     stored_time: "2023-06-29 14:24:44",
    //                     valid_term: "短期有效",
    //                     valid_time: "/",
    //                     total: 2,
    //                     rest: 1,
    //                     order_total: "4380.00",
    //                     service_total_price: "880.00",
    //                     service_single_price: "440.00",
    //                     order_create_person: "user2",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //             ],
    //             key: 4,
    //         },
    //         {
    //             name: "美舒黛透明质酸水润提升护理",
    //             service_times: 6,
    //             rest: 5,
    //             details: [
    //                 {
    //                     key: 92,
    //                     category: ["疗程"],
    //                     service_id: "7",
    //                     service_name: "美舒黛透明质酸水润提升护理",
    //                     order_code: "OD20230619845442",
    //                     create_time: "2023-06-19 09:29:26",
    //                     stored_time: "2023-06-19 09:29:26",
    //                     valid_term: "长期有效",
    //                     valid_time: "/",
    //                     total: 6,
    //                     rest: 5,
    //                     order_total: "3280.00",
    //                     service_total_price: "3280.00",
    //                     service_single_price: "546.67",
    //                     order_create_person: "user1",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user1",
    //                     notes: "",
    //                 },
    //             ],
    //             key: 5,
    //         },
    //         {
    //             name: "生姜温脉浴疗",
    //             service_times: 10,
    //             rest: 5,
    //             details: [
    //                 {
    //                     key: 95,
    //                     category: ["疗程"],
    //                     service_id: "26",
    //                     service_name: "生姜温脉浴疗",
    //                     order_code: "OD20230629282960",
    //                     create_time: "2023-06-29 14:24:44",
    //                     stored_time: "2023-06-29 14:24:44",
    //                     valid_term: "长期有效",
    //                     valid_time: "/",
    //                     total: 5,
    //                     rest: 3,
    //                     order_total: "4380.00",
    //                     service_total_price: "2300.00",
    //                     service_single_price: "460.00",
    //                     order_create_person: "user2",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 96,
    //                     category: ["疗程"],
    //                     service_id: "26",
    //                     service_name: "生姜温脉浴疗",
    //                     order_code: "OD20230629323508",
    //                     create_time: "2023-06-29 14:33:59",
    //                     stored_time: "2023-06-29 14:33:59",
    //                     valid_term: "长期有效",
    //                     valid_time: "/",
    //                     total: 5,
    //                     rest: 2,
    //                     order_total: "3500.00",
    //                     service_total_price: "2300.00",
    //                     service_single_price: "460.00",
    //                     order_create_person: "user2",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //             ],
    //             key: 6,
    //         },
    //         {
    //             name: "水氧活肤疗法",
    //             service_times: 6,
    //             rest: 3,
    //             details: [
    //                 {
    //                     key: 94,
    //                     category: ["疗程"],
    //                     service_id: "21",
    //                     service_name: "水氧活肤疗法",
    //                     order_code: "OD20230629282960",
    //                     create_time: "2023-06-29 14:24:44",
    //                     stored_time: "2023-06-29 14:24:44",
    //                     valid_term: "长期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 2,
    //                     order_total: "4380.00",
    //                     service_total_price: "1200.00",
    //                     service_single_price: "400.00",
    //                     order_create_person: "user2",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //                 {
    //                     key: 97,
    //                     category: ["疗程"],
    //                     service_id: "21",
    //                     service_name: "水氧活肤疗法",
    //                     order_code: "OD20230629323508",
    //                     create_time: "2023-06-29 14:33:59",
    //                     stored_time: "2023-06-29 14:33:59",
    //                     valid_term: "长期有效",
    //                     valid_time: "/",
    //                     total: 3,
    //                     rest: 1,
    //                     order_total: "3500.00",
    //                     service_total_price: "1200.00",
    //                     service_single_price: "400.00",
    //                     order_create_person: "user2",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //             ],
    //             key: 7,
    //         },
    //         {
    //             name: "BRD钥美之心",
    //             service_times: 2,
    //             rest: 2,
    //             details: [
    //                 {
    //                     key: 85,
    //                     category: ["疗程"],
    //                     service_id: "5",
    //                     service_name: "BRD钥美之心",
    //                     order_code: "OD20230611994758",
    //                     create_time: "2023-06-10 18:06:24",
    //                     stored_time: "2023-06-10 18:06:24",
    //                     valid_term: "短期有效",
    //                     valid_time: "/",
    //                     total: 2,
    //                     rest: 2,
    //                     order_total: "7500.00",
    //                     service_total_price: "5500.00",
    //                     service_single_price: "2750.00",
    //                     order_create_person: "user2",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //             ],
    //             key: 8,
    //         },
    //         {
    //             name: "轻肌FOEME",
    //             service_times: 1,
    //             rest: 1,
    //             details: [
    //                 {
    //                     key: 87,
    //                     category: ["服务"],
    //                     service_id: "16",
    //                     service_name: "轻肌FOEME",
    //                     order_code: "OD20230618225704",
    //                     create_time: "2023-06-18 13:57:25",
    //                     stored_time: "2023-06-18 13:57:25",
    //                     valid_term: "长期有效",
    //                     valid_time: "/",
    //                     total: 1,
    //                     rest: 1,
    //                     order_total: "16.00",
    //                     service_total_price: "1.00",
    //                     service_single_price: "1.00",
    //                     order_create_person: "user2",
    //                     order_create_shop: "金兰湾白鹭洲店",
    //                     stored_create_person: "user2",
    //                     notes: "",
    //                 },
    //             ],
    //             key: 9,
    //         },
    //     ],
    // },
    customerInfo: undefined,
    serviceInfo: undefined,
    dataSource: [],
    steps: 0,
    recordIdList: [],
    selectedRows: [],
    selectedRowData: [],
    serviceDetails: [], // Step 3
    serviceCode: undefined,
    serviceCart: [],
    serviceCartTemp: undefined, // 上半部分的粗略信息
};

const serviceDataSource = (state = data_all, action) => {
    switch (action.type) {
        case CUSTOMER_SERVICE_CUSTOMER_ID:
            return {
                ...state,
                customerId: action.payload,
            };
        case CUSTOMER_SERVICE_ORDER_CODE:
            return {
                ...state,
                orderCode: action.payload,
            };
        case CUSTOMER_SERVICE_TRANS_CODE:
            return {
                ...state,
                transCode: action.payload,
            };
        case CUSTOMER_SERVICE_CUSTOMER_NAME:
            return {
                ...state,
                customerName: action.payload,
            };
        case CUSTOMER_SERVICE_CUSTOMER_PHONE:
            return {
                ...state,
                customerPhone: action.payload,
            };
        case CUSTOMER_SERVICE_CUSTOMER_GENDER:
            return {
                ...state,
                customerGender: action.payload,
            };
        case CUSTOMER_SERVICE_EMPLOYEE_ID:
            return {
                ...state,
                employeeId: action.payload,
            };
        case CUSTOMER_SERVICE_EMPLOYEE_NAME:
            return {
                ...state,
                employeeName: action.payload,
            };
        case CUSTOMER_SERVICE_DEBT_AMOUNT:
            return {
                ...state,
                debtAmount: action.payload,
            };
        case CUSTOMER_SERVICE_ORDER_TOTAL:
            return {
                ...state,
                orderTotal: action.payload,
            };
        case CUSTOMER_SERVICE_CUSTOMER_INFO:
            return {
                ...state,
                customerInfo: action.payload,
            };
        case CUSTOMER_SERVICE_INFO:
            return {
                ...state,
                serviceInfo: action.payload,
            };
        case CUSTOMER_SERVICE_ORDER_DATASOURCE:
            return {
                ...state,
                dataSource: action.payload,
            };
        case CUSTOMER_SERVICE_STEPS:
            return {
                ...state,
                steps: action.payload,
            };
        case CUSTOMER_SERVICE_RECORD_ID_LIST:
            return {
                ...state,
                recordIdList: action.payload,
            };
        case CUSTOMER_SERVICE_SELECTED_ROWS:
            return {
                ...state,
                selectedRows: action.payload,
            };
        case CUSTOMER_SERVICE_SELECTED_ROW_DATA:
            return {
                ...state,
                selectedRowData: action.payload,
            };
        case CUSTOMER_SERVICE_DETAILS:
            return {
                ...state,
                serviceDetails: action.payload,
            };
        case CUSTOMER_SERVICE_CODE:
            return {
                ...state,
                serviceCode: action.payload,
            };
        case CUSTOMER_SERVICE_CART:
            return {
                ...state,
                serviceCart: action.payload,
            };
        case CUSTOMER_SERVICE_CART_TEMP:
            return {
                ...state,
                serviceCartTemp: action.payload,
            };
        default:
            return state;
    }
    // if (action.type === "customerInfoAppointment"){
    //     state = action.value,
    //     console.log('info state:', state, 'info action:', action)
    // }
};

export default serviceDataSource;
