import {
    DATA_EXPORT_STEPS,
    DATA_EXPORT_OVERVIEW,
    DATA_EXPORT_SELECTED_TEST_ID,
    DATA_EXPORT_SEARCH_QUERY,
    DATA_EXPORT_EDIT_DATA,
    DATA_EXPORT_TYPE,
    DATA_EXPORT_SELECTED_ROWS_ID,
    DATA_EXPORT_SELECTED_ROWS_DATA,
    DATA_EXPORT_SELECTED_STUDY_DATA,
} from "@/store/actions/data_export/types";

const data_all = {
    steps: 0,
    overview: [],
    selected_test_id: "",
    search_query: {},
    edit_data: {},
    type: "",
    selected_rows_id: [],
    selected_rows_data: [],
    selected_study_data: [],
};

const dataExportDataSource = (state = data_all, action) => {
    switch (action.type) {
        case DATA_EXPORT_STEPS:
            return {
                ...state,
                steps: action.payload,
            };
        case DATA_EXPORT_OVERVIEW:
            return {
                ...state,
                overview: action.payload,
            };
        case DATA_EXPORT_SELECTED_TEST_ID:
            return {
                ...state,
                selected_test_id: action.payload,
            };
        case DATA_EXPORT_SEARCH_QUERY:
            return {
                ...state,
                search_query: action.payload,
            };
        case DATA_EXPORT_EDIT_DATA:
            return {
                ...state,
                edit_data: action.payload,
            };
        case DATA_EXPORT_TYPE:
            return {
                ...state,
                type: action.payload,
            };
        case DATA_EXPORT_SELECTED_ROWS_ID:
            return {
                ...state,
                selected_rows_id: action.payload,
            };
        case DATA_EXPORT_SELECTED_ROWS_DATA:
            return {
                ...state,
                selected_rows_data: action.payload,
            };
        case DATA_EXPORT_SELECTED_STUDY_DATA:
            return {
                ...state,
                selected_study_data: action.payload,
            };
        default:
            return state;
    }
};

export default dataExportDataSource;
