// default component
import React from "react";
import { useNavigate } from "react-router-dom";

// antd
import { Form, Input, Button, Row, Col, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";

// css
import "./style.css"; // 引入样式文件

// api
import { login } from "@/api/user/account";

// cookie
import Cookies from "js-cookie";

function Login() {
    const navigate = useNavigate();
    const handleSubmit = async (values) => {
        let expiresTime = 1;
        console.log("111", values);

        // 显示loading提示
        const loadingMessage = message.loading("Logging...", 0);

        try {
            // 等待响应或超时
            // const msg = await Promise.race([login(values), new Promise((resolve, reject) => setTimeout(() => reject(new Error("请求超时")), 5000))]);
            const msg = await login(values);

            console.log("msg1", msg);

            if (msg.code === 200) {
                Cookies.set("token", msg.data.token, { expires: expiresTime });
                Cookies.set("id", msg.data.user_id, {
                    expires: expiresTime,
                });
                Cookies.set("shop_id", msg.data.shop_id, {
                    expires: expiresTime,
                });
                Cookies.set("is_superuser", msg.data.is_superuser, {
                    expires: expiresTime,
                });

                message.success("Login success");
                // 1秒以后跳转到主页，然后刷新页面
                setTimeout(() => {
                    navigate("/", { replace: true });
                    window.location.reload();
                }, 1000);
            } else {
                message.error(msg.msg);
            }
        } catch (error) {
            console.log("error:", error);
            message.error("Login failed, please try again later");
        } finally {
            // 关闭loading提示
            loadingMessage();
        }
    };
    return (
        <div className="body-container">
            <div className="container" id="container">
                <div className="left-container">
                    {/* <img src={logo} alt="logo" className="left-logo" /> */}
                    <div className="left-welcome-container">
                        <h1 className="left-welcome-title">Databiomsci</h1>
                        <p className="left-welcome-content">Data Simplified, Science Amplified</p>
                    </div>
                </div>
                <div className="form-container sign-in-container">
                    <Form
                        name="normal-login"
                        onFinish={async (values) => {
                            await handleSubmit(values);
                        }}
                    >
                        <h1 className="right-welcome-title">Welcome</h1>
                        <p style={{ color: "#888", marginBottom: "20px" }}>Please login to continue</p>{" "}
                        <Form.Item
                            name="username"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter username!",
                                },
                            ]}
                        >
                            <Input prefix={<UserOutlined />} size="large" placeholder="username" bordered={true} className="login-input" />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: "Please enter password!",
                                },
                            ]}
                        >
                            <Input.Password prefix={<LockOutlined />} size="large" placeholder="password" bordered={true} className="login-input" />
                        </Form.Item>
                        <Row>
                            <Col span={24}>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" size="middle" style={{ width: "100%" }}>
                                        Login
                                    </Button>
                                </Form.Item>
                            </Col>
                            {/* <Col span={5} push={5}>
                                <Form.Item>
                                    <Button type="link" size="middle" className="forget-link" href="https://databiomsci.com">
                                        Forgot password?
                                    </Button>
                                </Form.Item>
                            </Col> */}
                        </Row>
                    </Form>
                </div>
            </div>
        </div>
    );
}

export default Login;
