import { combineReducers, createStore } from "redux";

import orderDataSource from "./cu_order";
import serviceDataSource from "./cu_service";
import dataManagementDataSource from "./data_management";
import dataExportDataSource from "./data_export";

const rootReducer = combineReducers({
    orderDataSource,
    serviceDataSource,
    dataManagementDataSource,
    dataExportDataSource,
});

const Store = createStore(rootReducer);

export default rootReducer;
