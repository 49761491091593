/*
 * This file contains all the constants related to user module
 */

import { URL_USER_BASE } from "@/api/constants/baseurl.js";

// user login
export const URL_USER_LOGIN = URL_USER_BASE + "user_login/";
export const URL_USER_CURRENT_USER = URL_USER_BASE + "user_current_user/";
export const URL_USER_LOGOUT = URL_USER_BASE + "user_logout/";

// user account managemen
export const URL_GET_USER_INFO = URL_USER_BASE + "get_user_info/";
export const URL_UPDATE_USER_INFO = URL_USER_BASE + "update_user_info/";
export const URL_CHANGE_PASSWORD = URL_USER_BASE + "change_password/";

// user action history
export const URL_USER_ACTION_HISTORY = URL_USER_BASE + "user_action_history/";
// user list
export const URL_USER_LIST = URL_USER_BASE + "user_list/";

// reset password randomly
export const URL_RESET_PASSWORD_RANDOMLY = URL_USER_BASE + "reset_password_randomly/";

// toggle status
export const URL_TOGGLE_STATUS = URL_USER_BASE + "toggle_status/";

// toggle role
export const URL_TOGGLE_ROLE = URL_USER_BASE + "toggle_role/";

// create new user
export const URL_CREATE_NEW_USER = URL_USER_BASE + "create_new_user/";
